<template>
  <article>
    <Hero heading="Your email verification link has expired." :subheading="true">
      <template #subheading>
        <p>
          Please visit <a class="link" href="https://electrifyamerica.com">www.electrifyamerica.com</a> and submit a new
          email registration form. Afterwards, please verify your email address by following the instructions in the
          received message. The verification link will remain active for the 48 hours following its reception.
        </p>
        <br />
        <p>For any emergency, you can reach us directly 24 hours a day, 7 days a week by dialing 833-632-2778.</p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '../components/Hero/Hero.vue';

export default {
  name: 'expired-email',
  metaInfo: {
    title: 'Expired Email | Electrify America',
    meta: [
      {
        name: 'description',
        content: 'Your email verification link expired.',
      },
      { name: 'robots', content: 'noindex' },
    ],
  },
  components: { Hero },
};
</script>
